<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout grid-list-md pa-0 relative>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            Fine Collection Report
            <v-spacer></v-spacer>
            <print-buttons
              v-if="$auth.can('library-download') && fineCollectionData.length > 0"
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :downloadUrl="downloadUrl"
              :pdf="false"
              :excel="true"
            ></print-buttons>
          </v-card-title>

          <v-card outlined>
            <v-card-title outlined>
              <v-flex xs3>
                <v-student-search-field
                  @onSelectStudent="selectStudent"
                ></v-student-search-field>
              </v-flex>
              <v-flex xs3>
                <v-grade-field
                  v-model="grade_id"
                  :withAll="true"
                ></v-grade-field>
              </v-flex>
              <v-flex xs2>
                <v-calendar-field
                  id-val="from_date"
                  v-model="frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2>
                <v-calendar-field
                  id-val="to_date"
                  v-model="toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2>
                <search-button :permission="true" @action="get()">
                  Search
                </search-button>
              </v-flex>
            </v-card-title>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="fineCollectionData"
            :options.sync="pagination"
            :footer-props="footerProps"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">{{ item.book_name }}</td>
                <td class="text-xs-left">{{ item.student_name }}</td>
                <td class="text-xs-left">
                  {{ item.grade || "-" }}
                  <div>
                    <span
                      style="font-size: 10px; color: #666"
                      v-if="item.section"
                    >
                    ({{ item.section }})
                      <!-- ({{ item.enroll_code || "" }}) -->
                    </span>
                  </div>
                  
                </td>
                <td class="text-xs-left">{{ item.issue_date }}</td>
                <td class="text-xs-left">{{ item.return_date }}</td>
                <td class="text-xs-left">{{ item.status }}</td>
                <td class="text-xs-left">{{ item.fine.currency() }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
export default {
  mixins: [Mixins],

  data: () => ({
    downloadUrl: "",
    pagination: {
      itemsPerPage: 10,
    },
    footerProps: {
      "items-per-page-options": [10, 25, 50, 100, -1],
    },
    frmDate: "",
    toDate: "",
    enroll_code: "",
    grade_id: "",
    form: new Form(
      {
      },
      "library/api/fine-collection-report"
    ),
    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Book", align: "left", value: "book_name", sortable: false },
      {
        text: "Student Name",
        align: "left",
        value: "student_name",
        sortable: false,
      },
      {
        text: "Grade (Section)",
        align: "left",
        value: "grade_section",
        sortable: false,
      },
      {
        text: "Issue Date",
        align: "left",
        value: "issue_date",
        sortable: false,
      },
      {
        text: "Return Date",
        align: "left",
        value: "retrun_date",
        sortable: false,
      },
      {
        text: "Status",
        align: "left",
        value: "status",
        sortable: false,
      },
      {
        text: "Fine",
        align: "left",
        value: "fine",
        sortable: false,
      },
      // { text: "Action", align: "right", value: "action", sortable: false },
    ],
    fineCollectionData: [],
  }),
  watch: {
    pagination: function () {
      this.get();
    },
  },

  methods: {
    selectStudent(student) {
      if (student) this.enroll_code = student.enroll_code;
    },
    get() {
      let extraParams =
        "&enroll_code=" +
        this.enroll_code +
        "&grade_id=" +
        this.grade_id +
        "&from_date=" +
        this.frmDate +
        "&to_date=" +
        this.toDate;
      this.form
        .get(null, this.queryString(extraParams))
        .then(({ data }) => {
          this.fineCollectionData = data.data;
          this.downloadUrl = data.download_url;
          this.form.fireFetch = false;
        })
        .catch((e) => {});
    },
  },
};
</script>
